<template>
    <div class="login" v-show="!hasToken">
        <div class="card">
            <header>
                <!-- <div class="logo"></div> -->
                <div class="title">三个十大行动</div>
            </header>
            <div class="form">
                <el-form :model="form" :rules="rules" ref="form" size="big" @keyup.enter.native="handleSubmit" v-show="!showQR">
                    <div class="item">
                        <div class="label">账号</div>
                        <el-form-item label="" prop="username">
                            <el-input placeholder="请输入账号" v-model="form.username"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <div class="label">密码</div>
                        <el-form-item label="" prop="password">
                            <el-input show-password placeholder="请输入密码" v-model="form.password"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-checkbox v-model="form.remember" label="记住密码"></el-checkbox>
                    </div>
                    <div class="btns">
                        <el-button type="primary" class="logBtn" @click="handleSubmit('1')">登录到工作台</el-button>
                        <!-- <el-button type="primary" class="logBtn toDashboard" @click="handleSubmit('0')">登录到驾驶舱</el-button> -->
                    </div>
                </el-form>
                <div class="qr" v-show="showQR">
                    <div id="box">
                        <!-- <iframe :src="qrSrc" alt=""></iframe> -->
                    </div>
                    <div class="radio">
                        <el-radio-group v-model="toAdmin" @change="changeToPath">
                            <el-radio label="1">登录到工作台</el-radio>
                            <el-radio label="0">登录到驾驶舱</el-radio>
                            <!-- <el-radio v-model="toAdmin" label="1"></el-radio>
                            <el-radio v-model="toAdmin" label="0"></el-radio> -->
                        </el-radio-group>
                    </div>
                </div>
                <div class="corner" :class="{ scan: showQR }" @click="changeQR"></div>
            </div>
        </div>
        <div class="corner"></div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {
            form: {
                uuid: '13647d8c-7698-4662-ae19-12334',
                remember: false,
                username: '',
                password: '',
            },
            rules: {
                username: [{ required: true, message: '请输入账号', trigger: 'submit' }],
                password: [{ required: true, message: '请输入密码', trigger: 'submit' }],
            },
            toAdmin: '1',
            loading: false,
            idaasLogin: null,
            showQR: false,
            qrSrc: '',
            hasToken: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        // 提醒用户登录失效,保证只有一次弹窗
        if (localStorage.isLoginOut) {
            this.$message.error('登录已失效,请重新登录');
            localStorage.removeItem('token');
            localStorage.removeItem('uacCode');
            localStorage.removeItem('isLoginOut');
            localStorage.removeItem('vuex');
        }
        // this.scanLogin();
        if (localStorage.username) {
            this.form.username = localStorage.username;
            this.form.password = localStorage.password;
            this.form.remember = true;
        }
        this.toAdmin = localStorage.toAdmin || '1';
    },
    mounted() {
        this.loadQR();
    },
    destroyed() {
        window.removeEventListener('load', this.changeLoginType);
    },
    methods: {
        changeToPath(val) {
            localStorage.setItem('toAdmin', val);
        },
        changeQR() {
            // let origin = 'http://8.136.193.205:10000';
            // let client_id = '21ffW78jknZkRMHhjM0MHDf8sER';
            location.replace(process.env.VUE_APP_API_UAC_PATH);
            // this.showQR = !this.showQR
            // this.changeLoginType()
        },
        loadQR() {
            let AppKey = 'worship-bSt9j8enkI4mfb4OvmiUbe';
            let AppSecret = 'npaF7lIl1BB9oro9shMq3RJAkdjWWQ6Q9H01L1VL';
            let AppName = 'worship';
            let host1 = 'login.dg-work.cn';
            let host2 = 'login-pro.ding.zj.gov.cn';
            let redirect_uri = location.origin;
            this.qrSrc = `https://${host1}/oauth2/auth.htm?response_type=code&client_id=${AppName}&scope=get_user_info&authType=QRCODE&embedMode=true&redirect_uri=${redirect_uri}`;
            // window.open(`https://${host2}/oauth2/auth.htm?response_type=code&client_id=${AppName}&redirect_uri=${redirect_uri}&scope=get_user_info&authType=QRCODE`)
            // 响应式
            // let style = '#adapter-login-ddtalkGov iframe{height:400px}'
            // this.idaasLogin = new window.IDaaSLoginPlugin({
            //     serverUrl: "https://sso.ding.zj.gov.cn",
            //     portalUrl: "https://sso.ding.zj.gov.cn/frontend/login#/login",
            //     instanceId: "zzd",
            //     appId: "15e05749783dde4d290c841c0a2259f3HlpPFDK11xJ",
            //     styleUrl: "",
            //     styleTxt: style,
            //     box: "box",
            //     width: '100%',
            //     height: '100%',
            //     target: true,
            //     loginType: "qrGov"
            // });
            // console.log(this.idaasLogin);
            // window.addEventListener('load', this.changeLoginType)
        },
        scanLogin() {
            // ;
            let token = '';
            // 判断是uac登录的token,还是普通登录(localStorage是uac登录返回的token,query是普通登录)
            if (localStorage.token) {
                token = localStorage.token;
                axios.defaults.headers.common['token'] = token;
                this.$message.success('登录成功');
                this.$store.dispatch('getDict');
                // this.$store.dispatch('getMenu');
                this.$store.dispatch('user/userinfo');
                this.$store.dispatch('getRoleList');
                this.$store.dispatch('getAllDepartment');
                this.$store.dispatch('getUnreadFlge');
                this.$router.push('/home');
                localStorage.removeItem('uacCode');
            } else {
                token = this.$route.query.token;
            }
            if (!token) {
                return;
            }
        },
        changeLoginType() {
            this.idaasLogin.changeLoginType('qrGov');
        },
        handleSubmit(toAdmin) {
            this.$refs['form'].validate(valid => {
                if (!valid) {
                    return;
                }
                axios.post('/login', this.form).then(res => {
                    console.log('login', res);
                    if (res.token) {
                        if (res.isUac) {
                            this.$confirm('该账户不是内部用户， 请用统一登录平台登录')
                                .then(res => {
                                    location.replace(process.env.VUE_APP_API_UAC_PATH);
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                            return;
                        }
                        let token = res.token;
                        axios.defaults.headers.common['token'] = token;
                        localStorage.setItem('token', token);
                        if (this.form.remember) {
                            localStorage.setItem('username', this.form.username);
                            localStorage.setItem('password', this.form.password);
                        } else {
                            localStorage.removeItem('username');
                            localStorage.removeItem('password');
                        }
                        axios.get(`/front/frontTemplateNodePerson/getNodeIdsByUserId?id=1`).then(res => {
                            if (res.code == 200) {
                                console.log(res.data);
                            }
                        });
                        this.$store.dispatch('user/userinfo').then(() => {
                            // 先获取用户信息再跳转路由
                            let roles = this.$store.state.user.roles;
                            console.log(roles, Object.keys(roles).length == 0);
                            if (roles.indexOf('leader') > -1) {
                                this.$message.success('登录成功');
                                window.location.href =
                                    window.dashboardUrl + `/?token=${localStorage.token || ''}&username=${localStorage.nickName || ''}`;
                            } else if (roles.indexOf('approver') > -1) {
                                this.$message.success('登录成功');
                                this.$router.push('/instructManagement');
                            } else if (Object.keys(roles).length == 0) {
                                this.$router.push('/Error');
                            } else {
                                this.$message.success('登录成功');
                                this.$router.push('/home');
                            }
                        });
                    } else {
                        this.$router.push('/login');
                    }
                });
            });
        },
    },
};
</script>

<style scoped lang="stylus">
.login {
	background: #F6F7F8;
	color: $info;
	height: 100%;
	overflow: hidden;

	.card {
		width: 550px;
		height: 520px;
		background: #fff;
		margin: 200px auto 0;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		position: relative;

		header {
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;

			.logo {
				// background: url('~@/assets/img/logo.png') no-repeat
				width: 40px;
				height: 30px;
				background-size: 100% 100%;
			}

			.title {
				font-size: 24px;
				margin-left: 16px;
				font-weight: bold;
			}
		}

		.form {
			margin: 0 45px;

			.label {
				margin-bottom: 8px;
			}
		}

		.btns {
			text-align: center;
			margin-top: 44px;

			.logBtn {
				width: 300px;
				height: 40px;
				font-size: 16px;
			}

			.toDashboard {
				background: linear-gradient(90deg, #4EB9C6, #268394);
				margin: 20px 0 0;
				border-color: #4EB9C6;
			}
		}

		.corner {
			width: 100px;
			height: 100px;
			// background: url('~@/assets/img/corner.png') no-repeat
			background-position: 100% 100%;
			position: absolute;
			bottom: 0;
			right: 0;
			cursor: pointer;
		}

		.scan {
			// background-image: url('~@/assets/img/scan.png')
		}
	}
}

.qr {
	height: 370px;
	overflow: hidden;
	margin-top: -20px;

	iframe {
		height: 300px;
	}

	#box {
		height: 410px;
		width: 320px;
		overflow: hidden;
		margin: -85px auto 0;
	}

	.radio {
		text-align: center;
		margin-top: 20px;
	}
}
</style>
